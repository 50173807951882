import React from 'react';
import HorizonLabsInfo from '../HorizonLabsInfo/HorizonLabsInfo';
import HorizonServices from '../HorizonServices/HorizonServices';
import './index.scss';
import node from '../../assets/svg/node_js.svg';
import aws from '../../assets/svg/aws_logo.svg';
import reactIcon from '../../assets/svg/react_icon.svg';

const Technologies = () => {
  const services = [
    {
      serviceHeader: 'Node.js',
      icon: node,
      serviceText:
        'Node.js is a server-side technology that aids in creating a high-quality product and providing efficient and scalable applications.',
      fontStyle: {
        textAlign: 'center',
      },
      techIconWidth: {
        width: '70%',
      },
      flexTechIcon: {
        display: 'flex',
        justifyContent: 'center',
      },
    },

    {
      serviceHeader: 'Amazon Web Services',
      icon: aws,
      serviceText:
        'Amazon Web Services (AWS), is the cloud platform offered by Amazon, which we use to provide the best service for our customers.',
      fontStyle: {
        textAlign: 'center',
      },
      techIconWidth: {
        width: '65%',
      },
      flexTechIcon: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    {
      serviceHeader: 'React',
      icon: reactIcon,
      serviceText:
        'React is a technology that allows for lightning-fast website and mobile applications, whilst being scalable.',
      fontStyle: {
        textAlign: 'center',
      },
      techIconWidth: {
        width: '61%',
      },
      flexTechIcon: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    {
      serviceHeader: 'Rust',
      icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Rust_programming_language_black_logo.svg/1200px-Rust_programming_language_black_logo.svg.png',
      serviceText:
        'Rust is a multi-paradigm, high-level, general-purpose programming language that emphasizes performance, type safety, and concurrency.',
      fontStyle: {
        textAlign: 'center',
      },
      techIconWidth: {
        width: '70%',
      },
      flexTechIcon: {
        display: 'flex',
        justifyContent: 'center',
      },
    },

    {
      serviceHeader: 'Python',
      icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/640px-Python-logo-notext.svg.png',
      serviceText:
        'Python is a high-level, interpreted programming language that emphasizes code readability and simplicity. ',
      fontStyle: {
        textAlign: 'center',
      },
      techIconWidth: {
        width: '65%',
      },
      flexTechIcon: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    {
      serviceHeader: 'Golang',
      icon: 'https://go.dev/blog/go-brand/Go-Logo/PNG/Go-Logo_Blue.png',
      serviceText:
        'Go, also known as Golang, is a statically typed, compiled programming language designed for building scalable, efficient, and reliable software. ',
      fontStyle: {
        textAlign: 'center',
      },
      techIconWidth: {
        width: '61%',
      },
      flexTechIcon: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  ];

  return (
    <div className="tech-spacing">
      <HorizonLabsInfo
        coolTitle="TECHNOLOGIES"
        mainText="Latest And Modern Technologies"
        style={{
          borderTop: '1px solid',
        }}
        subText="To ensure a high quality product and serve our customers' needs, we use the latest and modern technologies such as Node.js, Amazon Web Services."
      />
      <div className="tech-image-spacing">
        {services.map((service) => {
          return (
            <HorizonServices
              key={service.serviceHeader}
              icon={service.icon}
              servicesHeader={service.serviceHeader}
              servicesText={service.serviceText}
              fontStyle={service.fontStyle}
              techIconSize={service.techIconWidth}
              flexTechIcon={service.flexTechIcon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Technologies;
