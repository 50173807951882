import React from 'react';
import HorizonServices from '../HorizonServices/HorizonServices';
import './index.scss';

import webDev from '../../assets/images/website-dev.png';
import mobileApp from '../../assets/images/mobileapp-dev.png';
import graphicDesign from '../../assets/images/graphic-design.png';
import eCommerce from '../../assets/images/e-commerce.png';
import ssl from '../../assets/images/ssl.png';
import digitalMarketing from '../../assets/images/digital-marketing.png';

const ServicesSection = () => {
  const services = [
    {
      serviceHeader: 'Website Development',
      icon: webDev,
      serviceText:
        'We provide Web Development services to ensure our client’s website and web applications are responsive and run effectively and smoothly.',
    },
    {
      serviceHeader: 'Mobile App Development',
      icon: mobileApp,
      serviceText:
        'Our mobile applications are created to the highest standard, in order to make sure the customer is provided with the service they need and deserve.',
    },
    {
      serviceHeader: 'AI-Driven Solutions',
      icon: graphicDesign,
      serviceText:
        'Experience AI-powered, innovative machine learning solutions for data-driven insights and streamlined processes. Utilize advanced algorithms to reduce manual tasks and revolutionize problem-solving.',
    },
    {
      serviceHeader: 'Software Development',
      icon: eCommerce,
      serviceText:
        'We work with various types of Software Development which include Front-end and Back-end Development, as well as Cloud Computing.',
    },
    {
      serviceHeader: 'Blockchain Development',
      icon: ssl,
      serviceText:
        'In addition to different types of software development such as Web and Mobile App Development, we also work with Blockchain Development.',
    },
    {
      serviceHeader: 'Solution Architect',
      icon: digitalMarketing,
      serviceText:
        'Our expert solution architects design and implement tailored, scalable technology solutions, driving digital transformation and seamless system integration.',
    },
  ];

  return (
    <div className="services-section-spacing">
      {services.map((service) => {
        return (
          <HorizonServices
            key={service.serviceHeader}
            icon={service.icon}
            servicesHeader={service.serviceHeader}
            servicesText={service.serviceText}
          />
        );
      })}
    </div>
  );
};

export default ServicesSection;
